<template>
  <div class="integrations">
    <div class="page_header">
      <h1>Интеграции</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Integrations"
}
</script>
